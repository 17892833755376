<template>
  <ul class="filter-tabs d-flex pl-0">
    <li
      v-for="item in filterList"
      :key="item.value"
      class="filter-tab cursor-pointer d-flex align-center icon-transition"
      :class="{ 'active': item.value === activeFilter }"
      @click="onFilterClick(item)"
    >
      <div>{{item.text}}</div>
      <UiCountBadge v-if="item.count" :count="item.count" small filled class="space-count-badge ml-2" />
    </li>
  </ul>
</template>

<script>
import UiCountBadge from "@/components/UI/UiCountBadge.vue";

export default {
  name: "FilterTabs",
  components: {UiCountBadge},
  data() {
    return {
      filter: "all",
      filterList: [
        { text: "All", value: undefined, count: 0 },
        { text: "Assets Pending", value: this.$config.project.projectModulesStatus.assetPending, count: 0 },
        { text: "Needs Review", value: this.$config.project.projectModulesStatus.needReview, count: 0},
        { text: "Approved Assets", value: this.$config.project.projectModulesStatus.approvedAsset, count: 0},
        { text: "New Projects", value: this.$config.project.projectModulesStatus.newProjects, count: 0},
      ],
      activeItemIdx: 0,
      debounce: null,
    };
  },
  computed: {
    activeFilter() {
      return this.$route.query.status ? Number(this.$route.query.status) : undefined;
    }
  },
  async mounted() {
    await this.getQuickFilterStatuses();
    this.$eventBus.$on('updateQuickFilterStatuses', async () => {
      await this.getQuickFilterStatuses()
    })
  },
  methods: {
    async onFilterClick(item) {
      if(item.value === this.activeFilter) return;
      await this.$router.push({ query: { ...this.$route.query, page: 1, status: item.value } })
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        this.$eventBus.$emit('projectModulesStatusChange', item.value);
      }, 600)
    },
    async getQuickFilterStatuses() {
      try {
        const res = await this.$api.dashboard.getQuickFilterStatuses()
        this.filterList = this.filterList.map(el => {
          const item = res.data.find(filter => filter.id === el.value)
          return {
            ...el,
            count: item?.count && item.count > 99 ? '99+' : item?.count || 0
          }
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.filter-tabs {
  list-style: none;
  gap: 28px;
  margin-bottom: -13px;
}
.filter-tab {
  border-bottom: 2px solid var(--v-gray-30-base);
  &.active {
    border-bottom: 2px solid var(--v-accent-80-base);
  }
}
</style>
