<template>
  <UiMenu
    bottom
    left
    offset-y
    :nudge-bottom="2"
    :min-width="156"
    :close-on-content-click="false"
    rounded="md"
    contentClass="table-columns-list"
  >
    <template v-slot:activator="{ on, attrs }">
      <UiBtn v-bind="attrs" v-on="on" @click.prevent icon color="gray-60">
        <IconSettings width="18"/>
      </UiBtn>
    </template>
    <v-list nav dense color="gray-0">
      <draggable
        :list="headers"
        :group="{ name: 'modules', pull: 'clone'}"
        handle=".drag-handle"
        class="fill-height fill-width"
      >
        <div v-for="(column, idx) in headers" :key="idx" class="workflow-table-row-item bordered-item d-flex align-center rounded pa-2">
          <div :class="['d-flex mr-2 drag-handle cursor-grab']">
            <IconMove3x3 width="8" class="gray-35--text"/>
          </div>
          <UiCheckbox
            v-model="column.hide"
            class="mt-0 pt-0"
            checkbox-form="square"
            hide-details
            :true-value="false"
            :false-value="true"
          >
            <template #label>
              <span class="text-capitalize">
                {{column.text || column.value}}
              </span>
            </template>
          </UiCheckbox>
        </div>
      </draggable>
    </v-list>
  </UiMenu>
</template>

<script>
import UiMenu from "@/components/UI/UiMenu";
import UiBtn from "@/components/UI/UiBtn";
import Draggable from "vuedraggable";
import UiCheckbox from "@/components/UI/UiCheckbox.vue";

export default {
  name: 'UiColumnsList',
  components: {
    UiCheckbox,
    Draggable,
    IconSettings: () => import("@/components/icons/IconSettings"),
    IconMove3x3: () => import("@/components/icons/IconMove3x3"),
    UiMenu,
    UiBtn
  },
  props: {
    headers: {
      type: Array,
      required: true
    }
  },
}
</script>

