<template>
  <div class="notification-card rounded" v-on="$listeners">
    <div class="d-flex align-center mb-3">
      <UIChip v-if="notification.space" class="mr-2" chipHeight="18px" showDot :text-color="notification.space.color" :backgroundColor="notification.space.color">
        <span class="text-captions-2 font-weight-bold">{{ notification.space.title }}</span>
      </UIChip>
      <div class="text-captions-2 font-weight-bold gray-60--text">{{ notification.project.title }}</div>
    </div>
    <div class="d-flex">
      <div>
        <v-tooltip color="accent" top>
          <template v-slot:activator="{ on, attrs }">
            <UiAvatar
              v-bind="attrs"
              v-on="on"
              size="26"
              text="My name"
              :src="notification.creator?.avatar?.url"
              class="card-bottom__actions__avatar mr-3"
            />
          </template>
          <span>{{ notification.creator.first_name }} {{ notification.creator?.last_name || '' }}</span>
        </v-tooltip>
      </div>
      <div class="pt-1">
        <div class="text-captions-1 gray-100--text mb-2"><span class="font-weight-bold">{{ notification.creator.first_name }} {{ notification.creator?.last_name?.[0] || '' }}</span> commented:</div>
        <div class="notification-description text-captions-1 gray-80--text mb-5" v-html="notification.description"/>
        <div class="text-captions-2 gray-60--text">{{formattedDate}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {format} from 'date-fns'
import UIChip from "@/components/UI/UIChip.vue";
import UiAvatar from "@/components/UI/UiAvatar.vue";

export default {
  name: "NotificationCard",
  components: {UiAvatar, UIChip},
  props: {
    notification: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      space: {
        color: 'in_progress'
      },
      date: new Date()
    }
  },
  computed: {
    formattedDate() {
      return format(new Date(this.notification.updated_at), "MMM do 'at' HH:mm")
    }
  },
}
</script>

<style scoped lang="scss">
.notification-card {
  padding: 10px;
  background: var(--v-gray-10-base);
}
.notification-description {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
