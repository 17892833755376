<template>
  <UiMenu
    bottom
    left
    offset-y
    :nudge-bottom="2"
    :min-width="156"
  >
    <template v-slot:activator="{ on, attrs }">
      <UiBtn v-bind="attrs" v-on="on" @click.prevent icon color="gray-60">
        <IconDotsH width="18"/>
      </UiBtn>
    </template>
    <v-list nav dense color="gray-10" :disabled="loading">
      <v-list-item
        :disabled="loading"
        @click="downloadSummary">
        <IconDownload width="18" class="mr-2 gray-60--text" />
        <v-list-item-title class="text-captions-1">Download Summary</v-list-item-title>
      </v-list-item>
      <v-list-item
        :disabled="!showEditAccessButton || loading"
        @click="openEditAccessModal">
        <IconShare width="18" class="mr-2 gray-60--text" />
        <v-list-item-title class="text-captions-1">Share</v-list-item-title>
      </v-list-item>
<!--      <v-list-item-->
<!--        v-if="project?.id"-->
<!--        :disabled="!getPermission(project?.id)?.dashboard['can-delete-dashboard'] || loading"-->
<!--        @click="deleteProject">-->
<!--        <IconDelete width="18" class="mr-2 gray-60&#45;&#45;text" />-->
<!--        <v-list-item-title class="text-captions-1">-->
<!--          Delete-->
<!--        </v-list-item-title>-->
<!--      </v-list-item>-->
    </v-list>
  </UiMenu>
</template>

<script>
import {mapGetters} from "vuex";
import UiMenu from "@/components/UI/UiMenu";
import UiBtn from "@/components/UI/UiBtn";
import projectCompleteMixin from "@/mixins/projectCompleteMixin"

export default {
  name: 'StyleCardMenu',
  components: {
    IconDotsH: () => import('@/components/icons/IconDotsH'),
    IconShare: () => import("@/components/icons/IconShare"),
    // IconDelete: () => import("@/components/icons/IconDelete"),
    IconDownload: () => import('@/components/icons/IconDownload'),
    UiMenu,
    UiBtn
  },
  mixins: [projectCompleteMixin],
  props: {
    project: {
      type: Object,
      default: null,
    },
    module: {
      type: Object,
      default: null,
    },
    getAssetsPending: {
      type: Function,
      default: () => {},
    },
    getPendingApproval: {
      type: Function,
      default: () => {},
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'getPermission',
      'getProjectPermissionData',
      'getAuthId',
    ]),
    showEditAccessButton() {
      const {projectOwner, projectManager} = this.$config.project.userRole;
      return (this.project?.user?.id === this.getAuthId ||
        this.getProjectPermissionData(this.project.id).roles.some(role => role === projectOwner || role === projectManager)
      )
    }
  },
  methods: {
    async openEditAccessModal() {
      await this.$store.commit('SET_PROJECT', this.project);
      this.$store.dispatch('openModal', {
        modalName: 'editModuleAccessModal',
        data: {
          module_id: this.module.id,
          module: this.module,
          project: this.project
        },
      });
    },
    // deleteProject() {
    //   if (!this.project?.id || !this.getPermission(this.project?.id)?.dashboard['can-delete-dashboard']) return;
    //
    //   this.$store.dispatch('openModal', {
    //     modalName: 'confirmModal',
    //     data: {
    //       title: 'Are you sure you want <br> to delete this project?',
    //       description: 'If you delete a project all its files will be archived and can be viewed through the files section',
    //       confirmBtnText: 'Delete',
    //     },
    //     handlers: {
    //       onCancel: () => {
    //         this.$store.dispatch('closeModal', 'confirmModal');
    //       },
    //       onConfirm: async () => {
    //         await this.$store.dispatch('closeModal', 'confirmModal');
    //
    //         try {
    //           this.$emit('setLoading', true)
    //           this.loading = true
    //           await this.$api.project.delete(this.project?.id);
    //           this.$emit('setLoading', false)
    //           await Promise.all([this.getAssetsPending(), this.getPendingApproval()])
    //           this.loading = false
    //
    //         } catch (error) {
    //           this.$emit('setLoading', false)
    //           this.loading = false
    //           console.error(error);
    //         }
    //       }
    //     }
    //   });
    // },
    async downloadSummary() {
      try {
        const {data} = await this.$api.project.downloadSummary(this.project.id, this.module.id)
        if(data?.url) {
          window.open(data.url, 'Download');
        }
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>
