<template>
  <div>
    <TableFilter
      :filter="queryParams"
      :filtersState="filtersState"
      @updateFilter="updateFilter"
      @loadMoreFilters="loadFilters"
      @updateData="getInfo(false)"
      @refetchFilters="refetchFilters"
    />
    <div class="table-top mb-3 d-flex justify-space-between align-center">
      <UiSwitch
        :input-value="$route.query.hide_viewed || '0'"
        inset
        color="accent-80"
        hide-details
        label="Hide Seen Updates"
        true-value="1"
        false-value="0"
        @change="toggleHideViewed"
      />
      <div class="text-captions-1">
        <span class="gray-60--text mr-1">{{pagination.total}} Assets</span>
        <span class="font-weight-bold mr-2">({{ this.tableItems.length }} Displayed)</span>
        <UiColumnsList :headers.sync="headers" @resetColumns="resetColumns" @saveColumns="updateColumnSettings" />
      </div>
    </div>
    <v-data-table
    v-model="selected"
    :headers="convertedHeaders"
    :items="tableItems"
    :items-per-page="this.tableItems.length"
    :expanded.sync="expanded"
    :loading="loadingData"
    :custom-sort="customSort"
    item-key="id"
    show-select
    hide-default-footer
    :hide-default-header="false"
    @click:row="onRowClick"
    class="dashboard-table"
  >
    <template v-slot:[`header.data-table-select`]="{ props }">
      <div class="ml-2">
        <UiSelectedIndicator
          class="cursor-pointer"
          :selected="props.value || props.indeterminate"
          :indeterminate="props.indeterminate"
          @click.stop="onSelectAll(props)"
        />
      </div>
    </template>
<!--    <template v-slot:header="{ props }">-->
<!--      <thead>-->
<!--        <tr>-->
<!--          <th-->
<!--            v-for="(header, index) in props.headers"-->
<!--            :key="index"-->
<!--            :class="['text-start', header.align]"-->
<!--            :style="{width: header.width ?  header.width + 'px' : 'auto', minWidth: header.width?  header.width + 'px' : 'auto'}"-->
<!--          >-->
<!--            <div class="header-content d-flex align-center text-captions-1 gray-100&#45;&#45;text cursor-pointer" @click="onSort(header)">-->
<!--              {{ header.text }}-->
<!--              <IconChevronDown-->
<!--                v-if="header.sortable"-->
<!--                width="15"-->
<!--                class="ml-1"-->
<!--                :class="[-->
<!--                  header.value === sortModel.sortBy ? 'gray-100&#45;&#45;text' : 'gray-60&#45;&#45;text',-->
<!--                  header.value === sortModel.sortBy && sortModel.sortMode === 'desc' ? 'rotate-180' : '',-->
<!--                ]"-->
<!--              />-->
<!--            </div>-->
<!--          </th>-->
<!--        </tr>-->
<!--      </thead>-->
<!--    </template>-->
    <template v-slot:item.data-table-select="{isSelected,select, item}">
      <div class="ml-2">
        <UiSelectedIndicator
          :selected="isSelected"
          @click.stop="isSelected ? selected = selected.filter(el => el.id !== item.id) : select($event)"
        />
      </div>
    </template>
    <template v-slot:item.project_name="{ item }">
      <div class="d-flex align-center">
        <div class="product-img flex-shrink-0">
          <UIImage class="default-image" v-if="!item?.item?.file" name-path="default-img.png"/>
          <img v-else-if="$config.filesystem.fileTypes.image.includes(getFileExtension(item.item?.file?.original_name))" :src="getFileThumbnails(item.item.file.thumbnails) || item.item.file.url" :alt="item?.project?.title"/>
          <UiFileIcon v-else width="24" :extension="getFileName(item.item?.file.original_name).ext"/>
        </div>
        <span v-if="item.is_multiple" class="project-title gray-100--text" :class="{'font-weight-bold': !item?.is_viewed}">{{item?.project?.title}}</span>
        <div v-else @click.stop>
          <router-link :to="getNavigateToAssetRoute(item)" class="text-decoration-none" target="_blank">
            <span class="gray-100--text" :class="{'font-weight-bold': !item?.is_viewed}">{{item?.project?.title}}</span>
            <IconExtLink width="14" class="ml-1 extlink-icon"/>
          </router-link>
        </div>
      </div>
    </template>
    <template v-slot:item.space="{ item }">
        <UIChip v-if="item.space" class="mr-2" chipHeight="18px" showDot :text-color="item.space.color" :backgroundColor="item.space.color">
          <span class="text-captions-2 font-weight-bold">{{ item.space.title }}</span>
        </UIChip>
    </template>
    <template v-slot:item.module="{ item }">
      <div v-if="item?.title" class="d-flex align-center">
        <UiProjectModuleIcon width="14" :icon="item.icon" class="module-icon mr-1 flex-shrink-0 gray-60--text"/>
        <div class="text-captions-1 gray-60--text">{{item.title}}</div>
      </div>
    </template>
    <template v-slot:item.style="{ item }">
      <div class="text-captions-1 gray-100--text text-truncate">{{ item?.item?.style?.title }}</div>
    </template>
    <template v-slot:item.version="{ item }">
      <div class="accent-80--text">
        {{item?.item?.version}}
      </div>
    </template>
    <template v-slot:item.updated_at="{ item }">
      <div v-if="item?.item?.updated_at" class="gray-60--text font-weight-bold">
        {{formatDate(item.item.updated_at)}}
      </div>
    </template>
    <template v-slot:item.update="{ item }">
      <UiProjectStatus :id="item.status" statusType="project" asSlot>
        <template v-slot="{ selectedStatus }">
          <div class="d-flex align-center justify-space-between" :style="{width: '100%'}" v-if="selectedStatus" >
            <UIChip
              chipHeight="18px"
              :text-color="selectedStatus.color"
              outlined
              :color="selectedStatus.color"
              :style="{opacity: item?.item?.status && typeof item?.item?.status === 'number' ? 100 : 0}"
            >
              <span class="text-captions-2 font-weight-bold">{{ selectedStatus.title }}</span>
            </UIChip>
            <IconLayers v-if="item.is_multiple" width="16" class="ml-2" :class="[ item?.item?.status && typeof item?.item?.status === 'number' ? `${selectedStatus.color}--text` : '']"/>
          </div>
        </template>
      </UiProjectStatus>
    </template>
    <template v-slot:item.due_date="{ item }">
      <span v-if="item?.project?.due_date" class="gray-60--text font-weight-bold">{{format(new Date(item.project.due_date), 'dd/MM/yyyy')}}</span>
    </template>
    <template v-slot:item.actions="{item}">
      <GlobalDashboardTableMenu
        :project="item.project"
        :module="item"
        @updateData="getInfo(false, true)"
        @setGlobalLoading="globalLoading = $event"
      />
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td
        v-for="(it, idx) in headers"
        :key="idx + item?.title"
        :style="{backgroundColor: item.is_viewed ?'var(--v-gray-10-base)' : '#fff'}"
      >
        <template v-if="it.value === 'select'">
          <div v-for="(inner, ind) in item.items" :key="inner.id + 'select' + idx + '-' + ind + '-' + item.id" class="inner-block px-4" @click="onRowClick({...item, is_multiple: false, item: inner})"/>
        </template>
        <template v-else-if="it.value === 'project_name'">
          <div v-for="(inner, ind) in item.items" :key="inner.id + 'project_name' + idx + '-' + ind + '-' + item.id" class="py-1 inner-block max-w-200 px-4" @click="onRowClick({...item, is_multiple: false, item: inner})">
            <div class="d-flex align-center">
              <div class="product-img inner flex-shrink-0">
                <UIImage class="default-image" v-if="!inner?.file" name-path="default-img.png"/>
                <img v-else-if="$config.filesystem.fileTypes.image.includes(getFileExtension(inner?.file?.original_name))" :src="getFileThumbnails(inner.file.thumbnails) || inner.file.url" :alt="inner?.file?.original_name"/>
                <UiFileIcon v-else width="17" :extension="getFileName(inner?.file.original_name).ext"/>
              </div>
              <div @click.stop class="max-width-100">
                <router-link :to="getNavigateToAssetRoute({...item, is_multiple: false, item: inner})" class="text-decoration-none d-flex max-width-100" target="_blank">
                  <div class="text-captions-1 gray-100--text text-truncate" :class="{'font-weight-bold': !item?.is_viewed}">{{inner?.file?.original_name}}</div>
                  <IconExtLink width="14" class="ml-1 flex-shrink-0"/>
                </router-link>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="it.value === 'space'">
          <div v-for="(inner, ind) in item.items" :key="inner.id + 'space' + idx + '-' + ind + '-' + item.id" class="inner-block px-4" @click="onRowClick({...item, is_multiple: false, item: inner})"/>
        </template>
        <template v-else-if="it.value === 'module'">
          <div v-for="(inner, ind) in item.items" :key="inner.id + 'module' + idx + '-' + ind + '-' + item.id" class="inner-block px-4" @click="onRowClick({...item, is_multiple: false, item: inner})"/>
        </template>
        <template v-else-if="it.value === 'style'">
          <div v-for="(inner, ind) in item.items" :key="inner.id + 'style' + idx + '-' + ind + '-' + item.id" class="py-1 inner-block text-truncate px-4" @click="onRowClick({...item, is_multiple: false, item: inner})">
            <div class="text-captions-1 gray-100--text text-truncate">{{ inner?.style?.title }}</div>
          </div>
        </template>
        <template v-else-if="it.value === 'version'">
          <div v-for="(inner, ind) in item.items" :key="inner.id + 'version' + idx + '-' + ind + '-' + item.id" class="py-1 inner-block text-truncate px-4" @click="onRowClick({...item, is_multiple: false, item: inner})">
            <div class="accent-80--text text-captions-1">
              {{inner?.version}}
            </div>
          </div>
        </template>
        <template v-else-if="it.value === 'updated_at'">
          <div v-for="(inner, ind) in item.items" :key="inner.id + 'updated_at' + idx + '-' + ind + '-' + item.id" class="py-1 inner-block text-truncate px-4" @click="onRowClick({...item, is_multiple: false, item: inner})">
            <div v-if="inner.updated_at" class="gray-60--text font-weight-bold text-captions-1">
              {{formatDate(inner.updated_at)}}
            </div>
          </div>
        </template>
        <template v-else-if="it.value === 'update'">
          <div v-for="(inner, ind) in item.items" :key="inner.id + 'update' + idx + '-' + ind + '-' + item.id" class="py-1 inner-block text-truncate px-4" @click="onRowClick({...item, is_multiple: false, item: inner})">
            <UiProjectStatus v-if="inner.status && typeof inner.status === 'number'" :id="inner.status" statusType="project" asSlot>
              <template v-slot="{ selectedStatus }">
                <div class="d-flex align-center" v-if="selectedStatus">
                  <UIChip
                    chipHeight="18px"
                    :text-color="selectedStatus.color"
                    outlined
                    :color="selectedStatus.color"
                  >
                    <span class="text-captions-2 font-weight-bold">{{ selectedStatus.title }}</span>
                  </UIChip>
                </div>
              </template>
            </UiProjectStatus>
          </div>
        </template>
        <template v-else-if="it.value === 'due_date'">
          <div v-for="(inner, ind) in item.items" :key="inner.id + 'due_date' + idx + '-' + ind + '-' + item.id" class="py-1 inner-block text-truncate px-4" @click="onRowClick({...item, is_multiple: false, item: inner})">
<!--              TODO use due date from item-->
            <span v-if="item?.project?.due_date" class="gray-60--text font-weight-bold text-captions-1">{{format(new Date(item.project.due_date), 'dd/MM/yyyy')}}</span>
          </div>
        </template>
        <template v-else-if="it.value === 'actions'">
          <div v-for="(inner, ind) in item.items" :key="inner.id + 'actions' + idx + '-' + ind + '-' + item.id" class="inner-block text-truncate px-4" @click="onRowClick({...item, is_multiple: false, item: inner})">
            <GlobalDashboardInnerMenu
              :project="item.project"
              :module="item"
              :item="inner"
              @updateData="getInfo(false, true)"
              @setGlobalLoading="globalLoading = $event"
            />
          </div>
        </template>
      </td>
    </template>
    <template v-slot:footer>
      <div class="d-flex justify-center" v-if="tableItems?.length && pagination.total > tableItems.length">
        <UiBtn
          class="accent-100--text text-body font-weight-medium text-decoration-underline underline-offset-1"
          text
          :loading="loadingData"
          @click="loadMore"
        >
          View More
        </UiBtn>
      </div>
    </template>
  </v-data-table>
    <PageFooter v-if="selected.length" class="px-0">
      <TableBulkActionsBar
        :selected="selected"
        :total-items="pagination.total"
        :items-displayed="pagination.to"
        :loading="loadingData"
        @toggleAll="onSelectAll({value: !$event})"
        @updateData="getInfo(false, true)"
      />
    </PageFooter>
  </div>
</template>

<script>
import {isEqual, getFileThumbnails, capitalizeText, highlightText, getFileExtension, getFileName} from "@/utils/helpers";
import UIChip from "@/components/UI/UIChip.vue";
import UiProjectModuleIcon from "@/components/UI/UiProjectModuleIcon.vue";
import {formatDistance, format} from "date-fns";
import GlobalDashboardTableMenu from "@/views/globalDashboardAll/GlobalDashboardTableMenu.vue"
import GlobalDashboardInnerMenu from "@/views/globalDashboardAll/GlobalDashboardInnerMenu.vue"
import UiSelectedIndicator from "@/components/UI/UiSelectedIndicator.vue";
import UIImage from "@/components/UI/UIImage.vue";
import UiBtn from "@/components/UI/UiBtn.vue";
import UiSwitch from "@/components/UI/UiSwitch.vue";
import UiColumnsList from "@/components/UI/UiColumnsList.vue";
import UiProjectStatus from "@/components/UI/UiProjectStatus.vue";
import PageFooter from "@/components/layout/PageFooter.vue";
import TableBulkActionsBar from "@/views/globalDashboardAll/TableBulkActionsBar.vue";
import TableFilter from "@/views/globalDashboardAll/TableFilter.vue";
import tableColumns from "@/mixins/tableColumns"
import UiFileIcon from "@/components/UI/UiFileIcon.vue";

export default {
  components: {
    UiFileIcon,
    TableFilter,
    TableBulkActionsBar,
    PageFooter,
    UiProjectStatus,
    UiColumnsList,
    UiSwitch,
    UiBtn,
    UIImage,
    UiSelectedIndicator,
    UiProjectModuleIcon,
    UIChip,
    // IconChevronDown: () => import("@/components/icons/IconChevronDown"),
    IconLayers: () => import("@/components/icons/IconLayers"),
    GlobalDashboardTableMenu,
    GlobalDashboardInnerMenu,
    IconExtLink: () => import("@/components/icons/IconExtLink")
  },
  mixins: [tableColumns],
  data () {
    const ITEMS_PER_PAGE = 10;
    return {
      tableName: 'dashboard-table',
      selected: [],
      expanded: [],
      defaultHeaders: [
        {
          text: 'Project Name',
          sortable: false,
          width: '200',
          value: 'project_name',
          hide: false,
        },
        { text: 'Space',  sortable: false,  width: '100', value: 'space', hide: false, },
        { text: 'Module',  sortable: false,  width: '150', value: 'module', hide: false, },
        { text: 'Style',  sortable: false,  width: '100', value: 'style', hide: false, },
        { text: 'Version',  sortable: false,  width: '100', value: 'version', hide: false, },
        { text: 'Last Updated',  sortable: false,  width: '150', value: 'updated_at', hide: false, },
        { text: 'Update',  sortable: false,  width: '100', value: 'update', hide: false, },
        { text: 'Due Date',  sortable: false,  width: '110', value: 'due_date', hide: false, },
        { text: '',  sortable: false,  width: '70', value: 'actions', hide: false, },
      ],
      tableItems: [],
      sortModel: {
        sortBy: null,
        sortMode: null,
      },
      loadingData: false,
      globalLoading: false,
      ITEMS_PER_PAGE,
      FILTER_ITEMS_COUNT: 15,
      pagination: {
        current_page: 1,
        from: 0,
        last_page: 0,
        per_page: ITEMS_PER_PAGE,
        to: 0,
        total: 0
      },
      debounce: null,
      filtersState: {
        spaces: {
          loading: false,
          search: '',
          page: 1,
          data: [],
          meta: null,
        },
        tags: {
          loading: false,
          search: '',
          page: 1,
          data: [],
          meta: null,
        },
        projects: {
          loading: false,
          search: '',
          page: 1,
          data: [],
          meta: null,
        },
        statuses: {
          loading: false,
          search: '',
          page: 1,
          data: [],
          meta: null,
        },
        modules: {
          loading: false,
          search: '',
          page: 1,
          data: [],
          meta: null,
        },
        date: {
          loading: false,
          search: '',
          data: [
            {title: 'Today', id: '0'},
            {title: 'Yesterday', id: '1'},
            {title: 'Last 7 days', id: '7'},
            {title: 'Last 30 days', id: '30'},
            {title: 'Last 3 Months', id: '90'},
            {title: 'Last 6 Months', id: '180'},
          ],
        },
      },
      columnDebounce: null
    }
  },
  computed: {
    queryParams() {
      return {
        page: this.$route.query.page || 1,
        count: this.$route.query.count || this.ITEMS_PER_PAGE,
        spaces: Array.isArray(this.$route.query.spaces) ? this.$route.query.spaces.map(el => Number(el)) : this.$route.query.spaces ? [Number(this.$route.query.spaces)]  : [],
        tags: Array.isArray(this.$route.query.tags) ? this.$route.query.tags.map(el => Number(el)) : this.$route.query.tags ? [Number(this.$route.query.tags)]  : [],
        projects: Array.isArray(this.$route.query.projects) ? this.$route.query.projects.map(el => Number(el)) : this.$route.query.projects ? [Number(this.$route.query.projects)]  : [],
        statuses: Array.isArray(this.$route.query.statuses) ? this.$route.query.statuses.map(el => Number(el)) : this.$route.query.statuses || this.$route.query.statuses == '0' ? [Number(this.$route.query.statuses)]  : [],
        status: this.$route.query.status || undefined,
        modules: Array.isArray(this.$route.query.modules) ? this.$route.query.modules.map(el =>el) : this.$route.query.modules ? [this.$route.query.modules]  : [],
        date: this.$route.query.date || null, // TODO ask BE about date,
        hide_viewed: this.$route.query.hide_viewed || undefined,
      }
    },
  },
  watch: {
    sortModel: {
      async handler() {
        // if (this.queryParams.page === 1) {
        //   await this.getInfo()
        // } else {
        //   this.pagination.current_page = 1;
        // }
        // await this.$router.$updateQueryParams({page: 1});
        if(this.$route.query.page && this.$route.query.page !== '1') {
          await this.$router.push({query: {...this.$route.query, page: 1}});
        }

        await this.getInfo(false)
      },
      deep: true,
    },
    queryParams: {
      handler() {
        this.selected = []
      }
    },
    headers: {
      async handler(val) {
        clearTimeout(this.columnDebounce)
        this.columnDebounce = setTimeout(async () => {
          await this.updateColumnSettings(val)
        }, 800)
      },
      deep: true
    },
  },
  async mounted() {
    const promises = [this.getModules(), this.getProjects(), this.getTags(), this.getStatuses(), this.getInfo(false), this.getColumnSettings()]
    await Promise.all(promises)
    await this.expandItemFromStart()
    await this.getFilterItemsByIds()
    this.isDataFetched = true
    this.statusChangeHandler()
  },
  beforeDestroy() {
    this.$eventBus.$off('projectModulesStatusChange');
  },
  methods: {
    isEqual,
    format,
    getFileThumbnails,
    getFileExtension,
    getFileName,
    async onExpanded(data) {
      const indexExpanded = this.expanded.findIndex(i => isEqual(i, data))
      if(indexExpanded >= 0) {
        this.expanded.splice(indexExpanded, 1)
      } else if(data.items.length > 1) {
        this.expanded.push(data);
      }
      await this.setRowsStyle();
    },
    getNavigateToAssetRoute(data) {
      if(data.item?.file) {
       return {
          name: 'DesignViewPage',
          params: {
            project_id: data.project.id,
          },
          query: {
            module_id: data.id,
            style_id: data.item.style.id,
            file_id: data.item.id,
            gallery_group_id: data.item.gallery_group_id,
          }
        }
      } else {
        return {
          name: 'ProjectDesign',
          params: {
            project_id: data.project.id,
          },
          query: {
            module: data.id,
          }
        }
      }
    },
    async navigateToAsset(data) {
      await this.$router.push(this.getNavigateToAssetRoute(data))
    },
    async onRowClick(data) {
      data.is_multiple ? await this.onExpanded(data) : await this.navigateToAsset(data)
    },
    formatDate(date) {
      return formatDistance(new Date(date), new Date(), {addSuffix: true}).replace('about ', '');
    },
    customSort(items, field, isDescending) {
      if (field.length && isDescending.length) {
        this.sortModel.sortBy = field[0];
        this.sortModel.sortMode = isDescending[0] ? 'desc' : 'asc';
      } else {
        this.sortModel.sortBy = null;
        this.sortModel.sortMode = null;
      }
      return items;
    },
    async loadMore() {
      this.$router.replace({query: {...this.$route.query, page: Number(this.$route.query.page || 1) + 1}});
      await this.getInfo(true)
    },
    async getInfo(merge, reloadAll) {
      try {
        this.loadingData = true
        const params = {
          count: this.queryParams.count,
          page: this.queryParams.page,
          order_by: this.sortModel.sortBy,
          order: this.sortModel.sortMode,

          space_ids: this.queryParams.spaces,
          project_ids: this.queryParams.projects,
          tag_ids: this.queryParams.tags,
          status_ids: this.queryParams.statuses,
          modules: this.queryParams.modules,
          date: this.queryParams.date,

          // hide_viewed: this.queryParams.hide_viewed === '1',
          hide_viewed: this.queryParams.hide_viewed,
          status: this.queryParams.status,

        }

        if(params.page > 1 && !this.tableItems.length || reloadAll) {
          params.count = params.page * this.ITEMS_PER_PAGE
          params.page = 1
        }
        const res = await this.$api.dashboard.getProjectModules(params)

        if(merge) {
          this.tableItems = [...this.tableItems, ...res.data];
        } else {
          this.expanded = []
          this.tableItems = res.data;
        }


        this.pagination = res.meta
        await this.setRowsStyle();
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingData = false
      }
    },
    async setRowsStyle() {
      await this.$nextTick();
      const tableEl = document.querySelector('.dashboard-table')
      if(tableEl) {
        const allRowsEls = tableEl.querySelectorAll('tbody > tr')
        allRowsEls.forEach(el => el.style.backgroundColor = 'white')
        const rowsEls = tableEl.querySelectorAll('tbody > tr:not(.v-data-table__expanded__content)')
        this.tableItems.forEach((el, idx) => {
          if(el.is_viewed) {
            if(rowsEls[idx]) {
              rowsEls[idx].style.backgroundColor = 'var(--v-gray-10-base)'
            }
          }
        })
      }
    },
    onSelectAll(data) {
      if(data.indeterminate || data.value) {
        this.selected = []
      } else if(!data.value && !data.indeterminate) {
        this.selected = JSON.parse(JSON.stringify(this.tableItems))
      }
    },
    async getModules(merge, titles) {
      this.filtersState[this.$config.dashboardConfig.filter_slugs.modules].loading = true
      try {
        const params = {
          count: this.FILTER_ITEMS_COUNT,
          page: this.filtersState[this.$config.dashboardConfig.filter_slugs.modules].page,
        }
        if(this.queryParams.projects.length) {
          params.project_ids = this.queryParams.projects
        }
        if(titles) {
          params.titles = titles
        }
        if(this.filtersState[this.$config.dashboardConfig.filter_slugs.modules]?.search?.length >= 2) {
          params.search = this.filtersState[this.$config.dashboardConfig.filter_slugs.modules]?.search
          params.page = 1
        }
        const res = await this.$api.dashboard.getFilterModules(params)

        const mapedData = res.data.map(el => ({
          ...el,
          id: el.title
        }))

        this.filterFetchDataSetter({merge, data: mapedData, type: this.$config.dashboardConfig.filter_slugs.modules, meta: res.meta, ids: titles})

      } catch (error) {
        console.error(error)
      } finally {
        this.filtersState[this.$config.dashboardConfig.filter_slugs.modules].loading = false
      }
    },
    async getProjects(merge, ids) {
      this.filtersState[this.$config.dashboardConfig.filter_slugs.projects].loading = true
      try {
        const params = {
          count: this.FILTER_ITEMS_COUNT,
          page: this.filtersState[this.$config.dashboardConfig.filter_slugs.projects].page,
        }

        if(ids?.length) {
          params.ids = ids
        }
        if(this.filtersState[this.$config.dashboardConfig.filter_slugs.projects]?.search?.length >= 2) {
          params.search = this.filtersState[this.$config.dashboardConfig.filter_slugs.projects]?.search
          params.page = 1
        }

        if(this.queryParams.spaces.length) {
          params.space_ids = this.queryParams.spaces
        }

        const res = await this.$api.dashboard.getFilterProjects(params)

        this.filterFetchDataSetter({merge, data: res.data, type: this.$config.dashboardConfig.filter_slugs.projects, meta: res.meta, ids})
      } catch (error) {
        console.error(error)
      } finally {
        this.filtersState[this.$config.dashboardConfig.filter_slugs.projects].loading = false
      }
    },
    async getTags(merge, ids) {
      this.filtersState[this.$config.dashboardConfig.filter_slugs.tags].loading = true
      try {
        const params = {
          count: this.FILTER_ITEMS_COUNT,
          page: this.filtersState[this.$config.dashboardConfig.filter_slugs.tags].page,
        }
        if(this.queryParams.projects.length) {
          params.project_ids = this.queryParams.projects
        }
        if(ids?.length) {
          params.ids = ids
        }
        if(this.filtersState[this.$config.dashboardConfig.filter_slugs.tags]?.search?.length >= 2) {
          params.search = this.filtersState[this.$config.dashboardConfig.filter_slugs.tags]?.search
          params.page = 1
        }
        const res = await this.$api.dashboard.getFilterTags(params)

        this.filterFetchDataSetter({merge, data: res.data, type: this.$config.dashboardConfig.filter_slugs.tags, meta: res.meta, ids: null})
      } catch (error) {
        console.error(error)
      } finally {
        this.filtersState[this.$config.dashboardConfig.filter_slugs.tags].loading = false
      }
    },
    async getStatuses(merge, ids) {
      this.filtersState[this.$config.dashboardConfig.filter_slugs.statuses].loading = true
      try {
        const params = {
          count: this.FILTER_ITEMS_COUNT,
          page: this.filtersState[this.$config.dashboardConfig.filter_slugs.statuses].page,
        }
        if(ids?.length) {
          params.ids = ids
        }
        const res = await this.$api.dashboard.getFilterStatuses(params)

        const mapedData = res.data.map(el => ({
          ...el,
          title: capitalizeText(el.title)
        }))

        if(merge) {
          this.filtersState[this.$config.dashboardConfig.filter_slugs.statuses].data = [...this.filtersState[this.$config.dashboardConfig.filter_slugs.statuses].data, ...mapedData];
        } else {
          this.filtersState[this.$config.dashboardConfig.filter_slugs.statuses].data = mapedData
        }
        if(!ids) {
          this.filtersState[this.$config.dashboardConfig.filter_slugs.statuses].meta = res.meta
        }

      } catch (error) {
        console.error(error)
      } finally {
        this.filtersState[this.$config.dashboardConfig.filter_slugs.statuses].loading = false
      }
    },
    updateFilter(type) {

      this.getInfo(false) // TODO add promises

      if(type === this.$config.dashboardConfig.filter_slugs.spaces) {
        this.filtersState[this.$config.dashboardConfig.filter_slugs.projects].page = 1
        this.getProjects()
      } else if(type === this.$config.dashboardConfig.filter_slugs.projects) {
        this.filtersState[this.$config.dashboardConfig.filter_slugs.modules].page = 1
        this.filtersState[this.$config.dashboardConfig.filter_slugs.tags].page = 1
        Promise.all([this.getModules(), this.getTags()])
      }
    },
    async toggleHideViewed(val) {
      await this.$router.push({query: {...this.$route.query, page: '1', hide_viewed: val}});
      await this.getInfo(false)
    },
    loadFilters(data) {
      if(data.type === this.$config.dashboardConfig.filter_slugs.projects) {
        this.getProjects(true)
      } else if(data.type === this.$config.dashboardConfig.filter_slugs.modules) {
        this.getModules(true)
      } else if(data.type === this.$config.dashboardConfig.filter_slugs.tags) {
        this.getTags(true)
      } else if(data.type === this.$config.dashboardConfig.filter_slugs.statuses) {
        this.getStatuses(true)
      }
    },
    async getFilterItemsByIds() {
      const tagsMissedIds = this.queryParams[this.$config.dashboardConfig.filter_slugs.tags].filter(el => !this.filtersState[this.$config.dashboardConfig.filter_slugs.tags].data.find(tag => tag.id === Number(el)))
      const projectsMissedIds = this.queryParams[this.$config.dashboardConfig.filter_slugs.projects].filter(el => !this.filtersState[this.$config.dashboardConfig.filter_slugs.projects].data.find(project => project.id === Number(el)))
      const modulesMissedTitles = this.queryParams[this.$config.dashboardConfig.filter_slugs.modules].filter(el => !this.filtersState[this.$config.dashboardConfig.filter_slugs.modules].data.find(module => module.id === el))
      const promises = []

      if(tagsMissedIds.length) {
        promises.push(this.getTags(true, tagsMissedIds))
      }
      if(projectsMissedIds.length) {
        promises.push(this.getProjects(true, projectsMissedIds))
      }
      if(modulesMissedTitles.length) {
        promises.push(this.getModules(true, modulesMissedTitles))
      }
      await Promise.all(promises)
    },
    filterFetchDataSetter({merge, data, type, meta, ids}) {
      if(merge) {
        const itemsToMerge = data.filter(el => !this.filtersState[type].data.find(item => item.id === el.id))
        this.filtersState[type].data = [...this.filtersState[type].data, ...itemsToMerge];
      } else {
        this.filtersState[type].data = data
      }

      if(!ids && !this.filtersState[type].search) {
        this.filtersState[type].meta = meta
      }

      if(this.filtersState[type].search) {
        this.filtersState[type].data.forEach(item => {
          item.htmlTitle = highlightText(this.filtersState[type].search || '', item.title || '');
        });
      }
    },
    refetchFilters() {
      Promise.all([this.getModules(), this.getProjects(), this.getTags()])
    },
    async expandItemFromStart() {
      if(!this.$route.query.expand_id) {
        return
      }
      const expandedId = Number(this.$route.query.expand_id)
      const itemToExpand = this.tableItems.find(el => el.id === expandedId)
      if(itemToExpand) {
        await this.onExpanded(itemToExpand)
        await this.$router.replace({query: {...this.$route.query, expand_id: undefined}})
      }
    },
    statusChangeHandler() {
      this.$eventBus.$on('projectModulesStatusChange', async data => {
        await this.getInfo()
      })
    },
    setExpandedAll() {
      this.expanded = this.tableItems.filter(el => el.is_multiple)
    },
  }
}
</script>

<style scoped lang="scss">
.dashboard-table {
  :deep(.v-data-table__wrapper) {
    padding-bottom: 10px;
  }
  :deep(table) {
    thead {
      &::after {
        content: "";
        display: block;
        height: 18px; // Задайте высоту, которую вы хотите для отступа
        background: var(--v-gray-0-base);
      }
      background: var(--v-gray-10-base);
      th {
        height: 32px !important;
        border-bottom: none !important;
        font-weight: 400;
        color: #171C29 !important;

        &:first-child {
          padding-left: 2px;
          padding-right: 2px;
        }

        &.sortable {
          i {
            &::before {
              content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNSAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMgNS45MTUyOUw3LjU3NDk0IDEwLjQ5MDJMMTIuMTQ5OSA1LjkxNTI5IiBzdHJva2U9IiM4MTg0OEIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
            }
          }
        }
      }
      .v-data-table__progress {
        th {
          height: auto !important;
          background: transparent;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        td {
          border-radius: 0 !important;
          border-bottom: 1px solid var(--v-gray-30-base);
          &.text-start {
            font-size: 12px;
            color: var(--v-gray-100-base);
          }
          &:first-child {
            padding-left: 2px;
            padding-right: 2px;
          }
        }
      }
      .v-data-table__expanded__content {
        box-shadow: none;

        td {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}
.header-content {
  width: fit-content;
}
.product-img {
  width: 26px;
  height: 26px;
  margin-right: 10px;
  :deep(img) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.inner {
    width: 18px;
    height: 18px;
    margin-left: 6px;
  }
}
.inner-block {
  max-height: 30px;
  min-height: 30px;
}
.max-w-200 {
  max-width: calc(200px); // TODO, use JS for dynamic width
}
.extlink-icon {
  margin-bottom: -2px;
}

//.table-top {
//  position: sticky;
//  top: 170px;
//  background-color: white;
//}
//
//.dashboard-table {
//  :deep(.v-data-table__wrapper) {
//    overflow-x: auto;
//    overflow-y: hidden;
//  }
//  :deep thead {
//    position: sticky;
//    top: 200px;
//    background: white;
//    z-index: 1;
//  }
//}
</style>
