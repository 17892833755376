<template>
  <div class="bulk-actions d-flex align-center">
    <div class="bulk-actions-counter text-captions-1">
      <strong class="font-weight-semi-bold accent--text text-body">({{ selected.length }})</strong>
      <span class="font-weight-semi-bold ml-1" style="margin-top: -2px;">Item(s) Selected</span>

      <div class="d-flex align-center mt-1" v-if="$vuetify.breakpoint.smAndUp">
        {{ totalItems }} Total Projects
        <span class="ml-1 font-weight-semi-bold">({{ itemsDisplayed }} Displayed)</span>

        <UiBtn text height="auto" color="accent" class="pa-0 font-weight-regular text-decoration-underline ml-3"
               @click="toggleAll(true)">
          Select All
        </UiBtn>

        <UiBtn text height="auto" color="accent" class="pa-0 font-weight-regular text-decoration-underline ml-2"
               @click="toggleAll(false)">
          Deselect
        </UiBtn>
      </div>
    </div>
    <div class="d-flex align-center ml-auto">
      <div class="d-flex align-center space-x-4" v-if="$vuetify.breakpoint.mdAndUp">
        <UiBtn outlined color="accent" :disabled="loading" @click="toggleModulesView(false)">
          <IconEyeOutlined width="15" class="mr-2"/>
          Mark as seen
        </UiBtn>
        <UiBtn outlined color="accent" :disabled="loading" @click="toggleModulesView(true)">
          <IconEyeCrossed width="15" class="mr-2"/>
          Mark as unseen
        </UiBtn>
      </div>
    </div>
  </div>
</template>

<script>
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'TableBulkActionsBar',
  components: {
    IconEyeCrossed: () => import("@/components/icons/IconEyeCrossed"),
    IconEyeOutlined: () => import("@/components/icons/IconEyeOutlined"),
    UiBtn,
  },
  props: {
    selected: {
      type: Array,
      default: () => []
    },
    totalItems: {
      type: Number,
      default: 0
    },
    itemsDisplayed: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleAll(val) {
      this.$emit('toggleAll', val);
    },
    async toggleModulesView(unview) {
      const payload = {
        ids: this.selected.map(el => el.id),
        unview: unview,
      }

      try {
        await this.$api.dashboard.toggleModulesView(payload);
        this.$emit('updateData')
      } catch (err) {
        console.error(err)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.bulk-actions {
  .bulk-actions-counter {
    .v-btn::before {
      display: none;
    }
  }
}
</style>

