<template>
  <div class="section pa-3 rounded relative">
    <div class="d-flex justify-space-between align-center mb-5">
      <div class="d-flex align-center">
        <h4 class="mr-1 text-body-md font-weight-medium">{{sectionTitle}}</h4>
        <UiCountBadge :count="count" small />
      </div>
      <UiBtn @click="viewMoreAction" :to="viewMoreTo" icon color="gray-60">
        <IconExpand width="20" height="20" />
      </UiBtn>
    </div>
    <div>
      <slot></slot>
    </div>
    <div class="d-flex justify-center mt-3">
      <UiBtn class="accent-100--text text-body font-weight-medium text-decoration-underline underline-offset-1" text @click="viewMoreAction" :to="viewMoreTo">View More</UiBtn>
    </div>
    <div v-if="loading" class="absolute inset-0 d-flex justify-center align-center gray-30"
         style="z-index: 2;" @click.prevent.self>
      <v-progress-circular
        :size="48"
        color="accent"
        indeterminate
      />
    </div>
  </div>
</template>

<script>
import UiCountBadge from "@/components/UI/UiCountBadge.vue";
import UiBtn from "@/components/UI/UiBtn.vue";

export default {
  name: "StyleSection",
  components: {
    UiBtn,
    UiCountBadge,
    IconExpand: () => import("@/components/icons/IconExpand")
  },
  props: {
    sectionTitle: {
      type: String,
      default: "",
    },
    viewMoreAction: {
      type: Function,
      default: () => {},
    },
    viewMoreTo: {
      type: [String, Object],
      default: '',
    },
    count: {
      type: [Number, String],
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style scoped lang="scss">
.section {
  border: 1px solid var(--v-gray-30-base);
}
</style>
