<template>
  <div v-if="loading" class="d-flex justify-center pt-16">
    <v-progress-circular
      :size="100"
      color="accent"
      indeterminate
    />
  </div>
  <div v-else class="dashboard-page d-flex flex-column fill-height">
    <PageHeader :maxContainerWidth="maxContainerWidth">
      <div class="d-flex justify-space-between align-center space-x-4">
        <h1 class="text-heading text-truncate">
          Global Dashboard
        </h1>
      </div>
      <FilterTabs class="mt-5" />
    </PageHeader>

    <v-container :style="{maxWidth: convertToUnit(maxContainerWidth)}">
      <DashboardTable />
    </v-container>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/PageHeader.vue";
import DashboardTable from "@/views/globalDashboardAll/DashboardTable.vue";
import FilterTabs from "@/views/globalDashboardAll/FilterTabs.vue";
import {convertToUnit} from "@/utils/helpers";

export default {
  name: "GlobalDashboardPageAll",
  components: {
    FilterTabs,
    DashboardTable,
    PageHeader,
  },
  data() {
    return {
      loading: true,
      maxContainerWidth: 1200,
    };
  },
  async created() {
    await this.$store.dispatch('setActiveSpace', null)
    this.$store.commit('SET_ACTIVE_FOLDER', null)
    this.loading = false
  },
  methods: {
    convertToUnit,
  }
}
</script>

<style scoped lang="scss">

</style>

