<template>
  <div class="bulk-actions d-flex align-center">
    <div class="bulk-actions-counter text-captions-1">
      <strong class="font-weight-semi-bold accent--text text-body">({{ selectedProjects.length }})</strong>
      <span class="font-weight-semi-bold ml-1" style="margin-top: -2px;">Item(s) Selected</span>

      <div class="d-flex align-center mt-1" v-if="$vuetify.breakpoint.smAndUp">
        {{ folderItems.length }} Total Folders
        <span class="ml-1 font-weight-semi-bold">({{ folderItems.length }} Displayed)</span>

        <UiBtn text height="auto" color="accent" class="pa-0 font-weight-regular text-decoration-underline ml-3"
               @click="toggleAll(true)">
          Select All
        </UiBtn>

        <UiBtn text height="auto" color="accent" class="pa-0 font-weight-regular text-decoration-underline ml-2"
               @click="toggleAll(false)">
          Deselect
        </UiBtn>
      </div>
    </div>
    <div class="d-flex align-center ml-auto">
      <div class="d-flex align-center space-x-4" v-if="$vuetify.breakpoint.mdAndUp">
        <UiBtn outlined color="accent" :disabled="!foldersToDelete.length" @click="deleteFolders">
          <IconDelete width="14" class="mr-2"/>
          Delete
        </UiBtn>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import UiBtn from "@/components/UI/UiBtn";
import projectCompleteMixin from "@/mixins/projectCompleteMixin"

export default {
  name: 'FolderBulkActionsBar',
  components: {
    IconDelete: () => import('@/components/icons/IconDelete'),
    UiBtn,
  },
  mixins: [projectCompleteMixin],
  computed: {
    ...mapGetters([
      'getDashboardFilters',
      'getProjectsList',
      'getActiveSpace'
    ]),
    folderItems() {
      return this.getProjectsList.filter(el => el.is_folder)
    },
    selectedProjects() {
      return this.getProjectsList.filter(project => project.checked);
    },
    foldersToDelete() {
      const {spaceAdmin} = this.$config.space.userRole;
      if(this.getActiveSpace?.currentSpaceUser?.user_role?.name !== spaceAdmin) {
        return []
      }

      return this.selectedProjects
    },
  },
  methods: {
    toggleAll(val) {
      const projects = this.getProjectsList.map(project => {
        if(project.is_folder) {
          return {...project, checked: val}
        }
        return project
      });
      this.$store.dispatch('setProjects', projects);
    },
    getLoadingFoldersId(folders) {
      const ids = folders?.map(folder => folder.folder_id) || [];

      const loadingFolders = this.getProjectsList.map(folder => ids.includes(folder.folder_id) ? {
        ...folder,
        loading: true
      } : folder);
      this.$store.dispatch('setProjects', loadingFolders);

      return ids;
    },
    deleteFolders() {
      if (!this.foldersToDelete.length) return;

      this.$store.dispatch("openModal", {
        modalName: "confirmModal",
        data: {
          title: "Delete Folder",
          description: "Are you sure want to delete this folders? Its content will be archived. This action is irreversible.",
          confirmBtnText: "Delete",
          confirmBtnIcon: '',
          cancelBtnColor: 'gray-60',
          showIconCross: true,
          actionBtnsPosition: 'right',
          modalMaxWidth: '416',
          descriptionAlign: 'left',
          descriptionColor: 'gray-100--text',
        },
        handlers: {
          onCancel: () => {
            this.$store.dispatch("closeModal", "confirmModal");
          },
          onConfirm: async () => {
            try {
              this.$store.dispatch('updateModalData', {
                modalName: 'confirmModal',
                data: {
                  loading: true,
                }
              })
              const ids = this.getLoadingFoldersId(this.foldersToDelete);

              const res = await this.$api.folder.delete(this.foldersToDelete.map(el => el.folder_id))

              const spaces = await this.$api.spaces.list()
              await this.$store.dispatch('setSpaces', spaces.data);

              const folders = this.getProjectsList.reduce((acc, folder) => {
                if (!ids.includes(folder.folder_id)) {
                  acc.push({...folder, loading: false});
                }
                return acc;
              }, []);
              await this.$store.dispatch('setProjects', folders);

              this.$store.dispatch('updateModalData', {
                modalName: 'confirmModal',
                data: {
                  loading: false,
                }
              })
              this.$toast.open({
                message: `Selected folders has been deleted`,
                type: 'success',
                position: 'top-right'
              });
              this.$store.dispatch("closeModal", "confirmModal");
            } catch(error) {
              console.error(error);
              this.$toast.open({
                message: 'Oops! Something went wrong!',
                type: 'error',
                position: 'top-right'
              });
              this.$store.dispatch('updateModalData', {
                modalName: 'confirmModal',
                data: {
                  loading: false,
                }
              })
            }
          },
        },
      });
    },
  },
}
</script>

<style scoped lang="scss">
.bulk-actions {
  .bulk-actions-counter {
    .v-btn::before {
      display: none;
    }
  }
}
</style>
