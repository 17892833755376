<template>
  <v-chip
    v-on="$listeners"
    v-bind="$attrs"
    :textColor="textColor"
    :color="color"
    :style="{
      height: chipHeight,
      minWidth: chipMinWidth
    }"
    class="chip"
    :class="{opacityBg: backgroundColor === textColor}"
  >
    <v-sheet v-if="showDot" :color="textColor" width="8" height="8" rounded="circle" class="mr-1"/>
    <slot/>
  </v-chip>
</template>

<script>
export default {
  name: "UIChip",
  props: {
    showDot: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      default: "",
    },
    chipHeight: {
      type: String,
      default: "auto",
    },
    chipMinWidth: {
      type: String,
      default: "auto",
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "transparent",
    },
    status: {
      type: [String, Number],
      default: "",
    }
  }
}
</script>

<style scoped lang="scss">
.chip {
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
}
.opacityBg {
  padding-left: 5px;
  padding-right: 5px;
  &::before {
    opacity: 0.2 !important;
  }
}
</style>
