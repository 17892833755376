import {mapGetters} from "vuex";
export default {
  data() {
    return {
      tableName: 'tableName',
      defaultHeaders: [],
      headers: [],
      isDataFetched: false,
    }
  },
  computed: {
    ...mapGetters({
      getUserColumnSettings: 'getUserColumnSettings',
      getUser: 'getUser',
    }),
    convertedHeaders() {
      return this.headers.filter(item => !item.hide);
    },
    canSetTableHeaders() {
      return Boolean(this.getUser) && Boolean(this.defaultHeaders?.length) && this.isDataFetched
    },
  },
  watch: {
    canSetTableHeaders: {
      handler(val) {
        if (val) {
          this.setTableHeaders();
          this.checkTableHeaders();
        }
      },
      immediate: true,
    },
  },
  methods: {
    resetColumns() {
      this.headers = JSON.parse(JSON.stringify(this.defaultHeaders));
      this.$store.dispatch('setTableColumns', {
        table: this.tableName,
        columns: this.defaultHeaders
      });
    },
    setTableHeaders() {
      if(this.getUserColumnSettings?.length) {
        this.headers = this.getUserColumnSettings
      } else {
        this.headers = JSON.parse(JSON.stringify(this.defaultHeaders));
      }
    },
    checkTableHeaders() {
      const headers = this.convertHeadersToSameFormat(this.headers);
      const defaultHeaders = this.convertHeadersToSameFormat(this.defaultHeaders);
      if((this.headers.length !== this.defaultHeaders.length) || (headers !== defaultHeaders)) {
        this.resetColumns();
      }
    },
    convertHeadersToSameFormat(headers) {
      headers = JSON.parse(JSON.stringify(headers));
      headers = headers.sort((a, b) => {
        if(a.value < b.value) { return -1; }
        if(a.value > b.value) { return 1; }
        return 0;
      });
      headers = headers.map(item => {
        delete item.hide;
        item.text = item.text?.trim();
        return Object.keys(item).map(key => `${key}:${item[key]}`).sort((a, b) => {
          if(a < b) { return -1; }
          if(a > b) { return 1; }
          return 0;
        });
      });
      return JSON.stringify(headers);
    },
    async getColumnSettings() {
      try {
        const res = await this.$api.dashboard.getColumnSettings()
        if(res.data?.length) {
          await this.$store.dispatch("setUserColumnSettings", res.data)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async updateColumnSettings(data) {
      if(!data?.length) {
        return
      }
      try {
        await this.$api.dashboard.updateColumnSettings({column_settings: data})
        await this.$store.dispatch("setUserColumnSettings", data)
      } catch (error) {
        console.error(error)
      }
    }
  },
}
